import { TokenService } from '@abp/auth/token.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from '@node_modules/file-saver';
import { AppConsts } from '@shared/AppConsts';
import { FileDto } from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';


@Injectable()
export class FileDownloadService {

    constructor(
        private _http: HttpClient,
        private _tokenService: TokenService,
    ) {
    }

    downloadTempFile(file: FileDto) {
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    downloadFile(storageItemId: string, controllerName: string): Observable<HttpResponse<Blob>> {
        const url = `${AppConsts.remoteServiceBaseUrl}/${controllerName}/DownloadDocument?storageItemId=${storageItemId}`;
        const headers = new HttpHeaders().set('Authorization', this.getAuthorizationHeader());
        return this._http.get(url, { headers, responseType: 'blob', observe: 'response'});
    }

    public saveFile = (response: HttpResponse<Blob>, fileName: string) => {
        const contentType = response.headers.get('content-type');
        const blob = new Blob([response.body], { type: 'application/octet-stream' });

        //If exception occured, json error returned;
        if (contentType && contentType.indexOf('application/json') !== -1) { return; }

        saveAs(blob, fileName);
    }

    private getAuthorizationHeader(): string {
        return 'Bearer ' + this._tokenService.getToken();
    }

}

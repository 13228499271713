
import { Injectable, Injector } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { PermissionCheckerService } from 'abp-ng2-module/dist/src/auth/permission-checker.service';
import { Observable, of as observableOf } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataBasedPreloadingStrategy implements PreloadingStrategy {

    private _permission: PermissionCheckerService;

    constructor(injector: Injector) {
        this._permission = injector.get(PermissionCheckerService);
    }

    preload(route: Route, load: Function): Observable<any> {
        if (!route.data || !route.data.preload) {
            return observableOf(null);
        }
        if (route.data.preload == true) {
            if (route.data.permission) {
                if (this._permission.isGranted(route.data.permission)) {
                    return load();
                }
            } else {
                return load();
            }
        }
        return observableOf(null);
    }
}

import { Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class IdentityReportNotificationsSignalrService extends AppComponentBase implements OnDestroy {

    private _identityReportNotificationSubject = new Subject<IdentityReportUpdateInfo>();

    constructor(
        injector: Injector,
        public _zone: NgZone
    ) {
        super(injector);
    }

    identityReportNotificationsHub: HubConnection;
    isIdentityReportNotificationsConnected = false;

    ngOnDestroy() {
        if (this.identityReportNotificationsHub) {
            this.identityReportNotificationsHub.off('oiiReportGenerated');
            this.identityReportNotificationsHub.stop();
        }

        this._identityReportNotificationSubject.complete();
    }

    configureConnection(connection): void {
        // Set the common hub
        this.identityReportNotificationsHub = connection;

        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isIdentityReportNotificationsConnected = false;
            if (e) {
                abp.log.debug('Identity Report notification connection closed with error: ' + e);
            } else {
                abp.log.debug('Identity Report notification disconnected');
            }

            if (!abp.signalr.autoConnect) {
                return;
            }

            setTimeout(() => {
                connection.start().then(result => {
                    this.isIdentityReportNotificationsConnected = true;
                    abp.event.trigger('app.notifications.identityReport.connected');
                });
            }, 5000);
        });

        // Register to get notifications
        this.registerIdentityReportNotificationEvents(connection);
    }

    registerIdentityReportNotificationEvents(connection): void {
        connection.on('oiiReportGenerated', message => {

            this._zone.run(() => {
                this._identityReportNotificationSubject.next(new IdentityReportUpdateInfo(message.siteId, message.teamName, message.userFullName, message.siteName));
            });
        });
    }

    getIdentityReportNotificationAsObservable(): Observable<IdentityReportUpdateInfo> {
        return this._identityReportNotificationSubject.asObservable();
    }

    init(): void {
        this._zone.runOutsideAngular(() => {
            //abp.signalr.connect();
            abp.signalr.startConnection(abp.appPath + 'signalr-oii-reports', connection => {
                this.isIdentityReportNotificationsConnected = true;
                this.configureConnection(connection);
            }).then(() => {
                abp.event.trigger('app.notifications.identityReport.connected');
            });
        });
    }
}

export class IdentityReportUpdateInfo {
    siteId: string;
    teamName: string;
    userFullName: string;
    siteName: string;

    constructor(siteId: string, teamName: string, userFullName: string, siteName: string) {
        this.siteId = siteId;
        this.teamName = teamName;
        this.userFullName = userFullName;
        this.siteName = siteName;
    }
}

import { DashboardCalculationType } from "@shared/service-proxies/service-proxies";

export class WidgetViewDefinition {
    id: string;
    defaultWidth: number;
    defaultHeight: number;
    title: string;
    dataType: DashboardCalculationType;
    displayAssessmentFilter: boolean;

    constructor(id: string, title: string, defaultWidth: number = 7, defaultHeight: number = 4, dataType: DashboardCalculationType = DashboardCalculationType.AccountabilityChartCreated, displayAssessmentfilter: boolean = false) {
        this.id = id;
        this.defaultWidth = defaultWidth;
        this.defaultHeight = defaultHeight;
        this.title = title;
        this.dataType = dataType;
        this.displayAssessmentFilter = displayAssessmentfilter;
    }
}

export class WidgetFilterViewDefinition {
    id: string;
    component: any;
    constructor(id: string, component: any) {
        this.id = id;
        this.component = component;
    }
}


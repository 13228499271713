import { Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class CapacityReportNotificationsSignalrService extends AppComponentBase implements OnDestroy {

    private _capacityReportNotificationSubject = new Subject<CapacityReportUpdateInfo>();

    constructor(
        injector: Injector,
        public _zone: NgZone
    ) {
        super(injector);
    }

    capacityReportNotificationsHub: HubConnection;
    isCapacityReportNotificationsConnected = false;

    ngOnDestroy() {
        if (this.capacityReportNotificationsHub) {
            this.capacityReportNotificationsHub.off('occReportGenerated');
            this.capacityReportNotificationsHub.stop();
        }

        this._capacityReportNotificationSubject.complete();
    }

    configureConnection(connection): void {
        // Set the common hub
        this.capacityReportNotificationsHub = connection;

        // Reconnect if hub disconnects
        connection.onclose(e => {
            this.isCapacityReportNotificationsConnected = false;
            if (e) {
                abp.log.debug('Capacity Report notification connection closed with error: ' + e);
            } else {
                abp.log.debug('Capacity Report notification disconnected');
            }

            if (!abp.signalr.autoConnect) {
                return;
            }

            setTimeout(() => {
                connection.start().then(result => {
                    this.isCapacityReportNotificationsConnected = true;
                    abp.event.trigger('app.notifications.capacityReport.connected');
                });
            }, 5000);
        });

        // Register to get notifications
        this.registerCapacityReportNotificationEvents(connection);
    }

    registerCapacityReportNotificationEvents(connection): void {
        connection.on('occReportGenerated', message => {
            abp.log.info(message);

            this._zone.run(() => {
                this._capacityReportNotificationSubject.next(new CapacityReportUpdateInfo(message.siteId, message.teamName, message.userFullName, message.siteName));
            });
        });
    }

    getCapacityReportNotificationAsObservable(): Observable<CapacityReportUpdateInfo> {
        return this._capacityReportNotificationSubject.asObservable();
    }

    init(): void {
        this._zone.runOutsideAngular(() => {
            //abp.signalr.connect();
            abp.signalr.startConnection(abp.appPath + 'signalr-occ-reports', connection => {
                this.isCapacityReportNotificationsConnected = true;
                this.configureConnection(connection);
            }).then(() => {
                abp.event.trigger('app.notifications.capacityReport.connected');
            });
        });
    }
}

export class CapacityReportUpdateInfo {
    siteId: string;
    teamName: string;
    userFullName: string;
    siteName: string;

    constructor(siteId: string, teamName: string, userFullName: string, siteName: string) {
        this.siteId = siteId;
        this.teamName = teamName;
        this.userFullName = userFullName;
        this.siteName = siteName;
    }
}

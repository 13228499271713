import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateRangeWidgetService } from '@app/admin/dashboard/customizable-dashboard/services/date-range-widget.sevice';
import { SelectListItem } from '@app/admin/shared/general-combo.component';
import { AssessmentServiceProxy, DashboardServiceProxy, SalesSummaryDatePeriod, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import {
  ChartComponent
} from 'ng-apexcharts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WidgetViewDefinition } from '../../definitions';
import { WidgetComponentBaseComponent } from '../widget-component-base';


@Component({
  selector: 'app-widget-sales-summary',
  templateUrl: './widget-sales-summary.component.html',
  styleUrls: ['./widget-sales-summary.component.css']
})


export class WidgetSalesSummaryComponent extends WidgetComponentBaseComponent implements OnInit, OnDestroy {
  public chartOptions: any
  @ViewChild('apexChart') chart: ChartComponent
  dashboardData: any = {};

  @Input('dateInterval') appSalesSummaryDateInterval: SalesSummaryDatePeriod = SalesSummaryDatePeriod.Weekly;
  @Input('title') title: string;
  @Input('data') data: WidgetViewDefinition;
  onDestroy: Subject<boolean> = new Subject<boolean>();
  value: number;
  increase: boolean;
  lastValue: number;
  displayIncrease: boolean;
  map: any;
  tenantIds: number[] = [];
  loadedData = false;
  selectedAssessment: string = undefined;
  assessments: SelectListItem[];
  companyIds: string[] = [];
  selectedEditionIds: number[] = [];

  constructor(injector: Injector,
    private _tenantDashboardServiceProxy: TenantDashboardServiceProxy,
    private _dashboardServiceProxe: DashboardServiceProxy,
    private _dateRangeWidgetService: DateRangeWidgetService,
    private _assessmentService: AssessmentServiceProxy) {
    super(injector);
  }

  ngOnInit(): void {
    if (!!this.appSession.tenantId) {
      this.tenantIds.push(this.appSession.tenantId);
    }

    this._dashboardServiceProxe.getKnowledgeLinkDashboardData(SalesSummaryDatePeriod.Weekly, this.data.dataType, this.tenantIds, this.selectedAssessment, this.companyIds, this.selectedEditionIds)
      .subscribe(r => {
        this.dashboardData.keys = r.data.map(a => a.key);
        this.dashboardData.values = r.data.map(b => b.value);
        this.value = r.value;
        this.lastValue = r.lastValue;
        this.increase = r.increase;
        this.displayIncrease = r.displayIncrease;
        if (this.dashboardData.values.length > 0) {
          this.loadedData = true;
        }
        this.initChart();
      });

    this._dateRangeWidgetService.dateRangeChangeTrigger$().pipe(takeUntil(this.onDestroy)).subscribe(res => this.onDateChange(res));
    this._dateRangeWidgetService.tenantIdChangeTrigger$().pipe(takeUntil(this.onDestroy)).subscribe(res => this.onTenantIdChange(res));
    this._dateRangeWidgetService.companyIdChangeTrigger$().pipe(takeUntil(this.onDestroy)).subscribe(res => this.onCompanyIdChange(res));
    this._dateRangeWidgetService.editionIdChangeTrigger$().pipe(takeUntil(this.onDestroy)).subscribe(res => this.onEditionIdChange(res));

    if (this.data.displayAssessmentFilter) {
      this.getAssessments();
    }

  }


  initChart() {
    this.chartOptions = {
      series: [
        {
          name: "Clicks",
          data: this.dashboardData.values
        }
      ],
      chart: {
        height: "90",
        width: "100%",
        type: "area",
        redrawOnParentResize: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }

      },
      dataLabels: {
        enabled: false
      },
      labels: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      yaxis: {
        // show: false, 
        labels: {
          show: false
        }
      },
      tooltip: {
        enabled: true,
        shared: false,
        marker: {
          show: false,
        },
      },

      title: {
        enabled: false
      },
      xaxis: {
        show: false,
        categories: this.dashboardData.keys,
        labels: {
          show: false
        },
        tooltip: {
          enabled: false,
        }

        // ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
      },
      grid: {
        show: false
      }
    };
    if (!!this.chart) {
      this.chart.render();
    }
  }

  onDateChange(dateInterval: SalesSummaryDatePeriod) {
    this.appSalesSummaryDateInterval = dateInterval;
    this.reinitChart();
  }

  onTenantIdChange(tenantId: number[]) {
    this.tenantIds = tenantId;
    this.reinitChart();
  }

  onCompanyIdChange(companyId: string[]) {
    this.companyIds = companyId;
    this.reinitChart();
  }

  selectedAssessmnentChange(assessmentId: string) {
    this.selectedAssessment = assessmentId;
    this.reinitChart();
  }

  onEditionIdChange(editionId: number[]) {
    this.selectedEditionIds = editionId;
    this.reinitChart();
  }


  public reinitChart() {
    this.loadedData = false;

    this._dashboardServiceProxe.getKnowledgeLinkDashboardData(this.appSalesSummaryDateInterval, this.data.dataType, this.tenantIds, this.selectedAssessment, this.companyIds, this.selectedEditionIds)
      .subscribe(r => {
        if (r.data.length > 0) {
          this.loadedData = true;
        }
        this.dashboardData.keys = r.data.map(a => a.key);
        this.dashboardData.values = r.data.map(b => b.value);
        this.value = r.value;
        this.increase = r.increase;
        this.lastValue = r.lastValue;
        this.displayIncrease = r.displayIncrease;

        if (this.dashboardData.values && this.dashboardData.values.length > 0) {
          setTimeout(() => {
            this.chart.updateSeries([{ data: this.dashboardData.values }], true);
            this.chart.updateOptions({
              xaxis: {
                categories: this.dashboardData.keys
              }
            }, true, true, true);
          }, 0);
        }
      });
  }

  getAssessments() {
    this._assessmentService.getAssessments(undefined, undefined, undefined, undefined, undefined, undefined, 1000, 0).subscribe(r => {
      this.assessments = r.items.map(a => new SelectListItem(a.id, a.name, false));
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}

import { AfterViewInit, Component, Injector, Input } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'friend-profile-picture',
    template:
    `<img [src]="profilePicture" class="{{cssClass}}" (error)="processError()" alt="...">`
})
export class FriendProfilePictureComponent extends AppComponentBase implements AfterViewInit {

    @Input() profilePictureId: string;
    @Input() userId: number;
    @Input() tenantId: number;
    @Input() cssClass = 'media-object';

    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defalutProfilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy
    ) {
        super(injector);
    }

    ngAfterViewInit(): void {
        this.setProfileImage();
    }

    private setProfileImage(): void {
        if (!this.profilePictureId) {
            this.profilePicture = this.defalutProfilePicture;
        } else {
            this.profilePicture = AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetProfilePictureById?id=' + this.profilePictureId;

            // this._profileService.getProfilePictureById(this.pictureId).subscribe(result => {
            //     if (result && result.profilePicture) {
            //         this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            //     } else {
            //         this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
            //     }
            // });
        }


        // if (!this.profilePictureId) {
        //     this.profilePictureId = undefined;
        // }

        // if (!this.tenantId) {
        //     this.tenantId = undefined;
        // }

        // if (!this.profilePictureId) {
        //     return;
        // }

        // this._profileService.getFriendProfilePictureById(this.profilePictureId, this.userId, this.tenantId).subscribe((result) => {
        //     if (result && result.profilePicture) {
        //         this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
        //     }
        // });
    }

    processError() {
        if (this.profilePicture != this.defalutProfilePicture) {
            this.profilePicture = this.defalutProfilePicture;
        }
    }
}

import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';


@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenants', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('TenantDashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/admin/dashboard'),
            new AppMenuItem('MessageBoard', 'Pages.MessageBoard.View', 'jour-menu-icon jour-FF__1_Human-Quotient-Hub', '/app/admin/message-boards'),
            new AppMenuItem('SepDashboard', 'Pages.SEP.Dashboard', 'jour-menu-icon jour-FF__2_SEP-Dashboard', '/app/admin/spdashboard', '', AppConsts.sapTenantId),
            new AppMenuItem('MessageBoard', 'Pages.MessageBoard.Manage', 'jour-menu-icon FF__1_Human-Quotient-Hub', '/app/admin/manage-message-boards'),
            new AppMenuItem('Companies', 'Pages.Companies', 'jour-menu-icon jour-companies', '/app/admin/companies'),
            new AppMenuItem('MySEPList', 'Pages.SEP.List', 'jour-menu-icon jour-FF__3_My-Tactical-Vison-Map', '/app/admin/sep', '', AppConsts.sapTenantId),
            new AppMenuItem('MattersOfImportance', 'Pages.CompanyUsers.MattersOfImportance.ViewMyData', 'jour-menu-icon jour-FF__5_Matters-of-Importance', '/app/admin/matters-of-importance', AppConsts.sapTenantId),
            new AppMenuItem('MySepObservationZones', 'Pages.SEP.ViewMyData', 'jour-menu-icon jour-FF__6_My-Observation-Zone', '/app/admin/observation-zones', '', AppConsts.sapTenantId),
            new AppMenuItem('SepNinetyDaysDeco', 'Pages.SEP.NinetyDaysDeco', 'jour-menu-icon jour-FF__7_90Day-Deco', '/app/admin/ninety-day-deco', '', AppConsts.sapTenantId),
            new AppMenuItem('MeSessions', 'Pages.CompanyUsers.MeSessions.ViewMyData', 'jour-menu-icon jour-FF__4_Measured-Effect-Session', '/app/admin/me-sessions'),
            new AppMenuItem('SepAnnualPlanner', 'Pages.SEP.AnnualPlanner', 'jour-menu-icon jour-annual-planner', '/app/admin/annual-planners', '', AppConsts.sapTenantId),
            new AppMenuItem('Wins', 'Pages.Wins.ViewMyData', 'jour-menu-icon jour-contract', '/app/admin/wins'),
            new AppMenuItem('CoachingSessions', 'Pages.CompanyUsers.CoachingSessions.ViewMyData', 'jour-menu-icon jour-coaching-sessions', '/app/admin/coaching-sessions'),
            new AppMenuItem('CoachingSessionV2', 'Pages.CompanyUsers.CoachingSessions_V2.ViewMyData', 'jour-menu-icon jour-coaching-sessions', '/app/admin/v2-coaching-sessions'),
            new AppMenuItem('WheelOfLife', 'Pages.CompanyUsers.WheelOfLife.ViewMyData', 'jour-menu-icon jour-wheel-of-life', '/app/admin/wheel-of-life'),
            new AppMenuItem('MyDocuments', 'Pages.CompanyUsers.Documents.ViewMyData', 'jour-menu-icon jour-FF__8_My-Documents', '/app/admin/documents'),
            new AppMenuItem('Institutions', 'Pages.Tenants', 'jour-menu-icon jour-institutions', '/app/admin/institutions'),
            new AppMenuItem('DueDiligence', 'Pages.JRS.DueDiligence', 'jour-menu-icon jour-due-dilegance', '/app/admin/due-diligence', 'Pages.SEP.List'),
            new AppMenuItem('MySAL', 'Pages.CompanyUsers.SAL.ViewMyData', 'jour-menu-icon jour-sal', '/app/admin/jgl', 'Pages.SEP.List'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('DnaAdvisors', 'Pages.Administration.Users.DNAAdvisors', 'jour-menu-icon jour-dna-advisors', '/app/admin/dna-advisors'),
            new AppMenuItem('DnaCategories', 'Pages.DNA.Assessment.Manage', 'jour-menu-icon jour-dna', '/app/admin/dna-categories'),
            new AppMenuItem('FiveWhys', 'Pages.CompanyUsers.FiveWhys.ViewMyData', 'jour-menu-icon jour-5-whys', '/app/admin/five-whys'),
            new AppMenuItem('GainAndLosses', 'Pages.CompanyUsers.GainAndLosses.ViewMyData', 'jour-menu-icon jour-gain-and-looses', '/app/admin/gain-and-losses'),
            new AppMenuItem('DnaProfile', 'Pages.DNA.CreateMyProfile', 'jour-menu-icon jour-dna', '/dna-profile/view/'),
            new AppMenuItem('ApproveAccess', 'Pages.CompanyUsers.RequestAccess.ViewMyData', 'jour-menu-icon jour-approve-access', '/app/admin/request-accesses'),
            new AppMenuItem('Forms', 'Pages.CompanyUsers.Forms', 'flaticon-folder-1', '/app/admin/forms-builder'),
            new AppMenuItem('BusinessIdentityAssessment', 'Pages.CompanyUsers.BusinessIdentityAssessment', 'flaticon-edit-1', '/app/admin/identity-assessment'),
            new AppMenuItem('CapacityAssessment', 'Pages.CompanyUsers.CapacityAssessment', 'flaticon-notes', '/app/admin/capacity-assessment'),


            new AppMenuItem('Administration', '', 'jour-menu-icon jour-administration', '', '', undefined, [
                new AppMenuItem('AssessmentsSetup', 'Pages.ManageAssessments', 'jour-menu-icon jour-assessments', '/app/admin/assessments'),
                new AppMenuItem('AssessmentCategories', 'Pages.AssessmentCategories', 'jour-menu-icon jour-assessment-category', '/app/admin/assessment-categories'),
                new AppMenuItem('Admins', 'Pages.Administration.Users.Admins', 'jour-menu-icon jour-admins', '/app/admin/admin-users'),
                new AppMenuItem('Coaches', 'Pages.Administration.Users.Coaches', 'jour-menu-icon jour-coaches', '/app/admin/coaches'),
                new AppMenuItem('ValuedTeamMembers', 'Pages.Administration.Users.ValuedTeamMembers', 'jour-menu-icon jour-coaches', '/app/admin/valued-team-members'),
                //new AppMenuItem('FormsBuilder', 'Pages.Administration.Users.FormsBuilder', 'flaticon-folder-1', '/app/admin/forms-builder'),
                //new AppMenuItem('BusinessIdentityAssessment', 'Pages.Administration.Users.BusinessIdentityAssessment', 'flaticon-folder-1', '/app/admin/identity-assessment'),
                //new AppMenuItem('CapacityAssessment', 'Pages.Administration.Users.CapacityAssessment', 'flaticon-folder-1', '/app/admin/capacity-assessment'),
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'jour-menu-icon jour-manage-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'jour-menu-icon jour-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'jour-menu-icon jour-settings', '/app/admin/institutionSettings')
            ]),

            new AppMenuItem('Data Gathering', '', 'jour-menu-icon jour-administration', '', '', undefined, [               
                // new AppMenuItem('FormsBuilder', 'Pages.Administration.Users.FormsBuilder', 'flaticon-folder-1', '/app/admin/forms-builder'),
                // new AppMenuItem('BusinessIdentityAssessment', 'Pages.Administration.Users.BusinessIdentityAssessment', 'flaticon-edit-1', '/app/admin/identity-assessment'),
                // new AppMenuItem('CapacityAssessment', 'Pages.Administration.Users.CapacityAssessment', 'flaticon-notes', '/app/admin/capacity-assessment'),
                new AppMenuItem('OperationalIdentityIndexMenuItem', 'Pages.Tenants', 'flaticon-edit-1', '/app/admin/oii-team-reports'),
                new AppMenuItem('OperationalChangeCapacityMenuItem', 'Pages.Tenants', 'flaticon-notes', '/app/admin/occ-team-reports'),
                new AppMenuItem('OperationalIdentityIndexMenuItem', 'Pages.OII.Sites.AdminView', 'flaticon-edit-1', '/app/admin/oii-sites'),
                new AppMenuItem('OperationalChangeCapacityMenuItem', 'Pages.OCC.Sites.AdminView', 'flaticon-notes', '/app/admin/occ-sites'),
                new AppMenuItem('Assessments', 'Pages.ManageAssessmentResults', 'flaticon-folder-1 menu-item', '/app/admin/assessment-strategies'),
            ]),

            new AppMenuItem('SEPCuriculum', 'Pages.SEP.Curiculum', 'jour-menu-icon jour-FF__10_SEP-Curriculum', '', '', undefined, [
                new AppMenuItem('Curiculum', 'Pages.SEP.Curiculum.Circulum', 'jour-menu-icon jour-FF__10a_Curriculum', '/app/admin/sep-curiculum'),
                // , '', undefined, [
                //     new AppMenuItem(' Your Discovery of the Strategic Enterprise Program™', 'Pages.SEP.Curiculum.SEP', 'jour-menu-icon jour-settings', '/app/admin/sep-curiculum-discovery'),
                //     new AppMenuItem('Developing Your Missioned Purpose', 'Pages.SEP.Curiculum.DevelopingPurpose', 'jour-menu-icon jour-settings', '/app/admin/sep-curiculum-developing-purpose'),
                //     new AppMenuItem('Developing Your Core Values Of Leadership', 'Pages.SEP.Curiculum.DevelopingCoreValues', 'jour-menu-icon jour-settings', '/app/admin/sep-curiculum-developing-core-value'),
                //     new AppMenuItem('Developing Your Dream', 'Pages.SEP.Curiculum.DevelopingDream', 'jour-menu-icon jour-settings', '/app/admin/sep-curiculum-developing-dream'),
                //     new AppMenuItem('Constructing Your Dream Vision: Building a Vision Blueprint', 'Pages.SEP.Curiculum.VisionBlueprint', 'jour-menu-icon jour-settings', '/app/admin/sep-curiculum-vision-blueprint'),
                // ]),
                new AppMenuItem('Assignments', 'Pages.SEP.IntakeForm', 'jour-menu-icon jour-FF__10b_Assignment', '/app/admin/assignments', '', AppConsts.sapTenantId),
                new AppMenuItem('RitualsOfSelfOrder', 'Pages.SEP.Curiculum.ROSO', 'jour-menu-icon jour-FF__10c_Rituals-of-Self-Order', '/app/admin/roso'),


                // new AppMenuItem('RitualsOfSelfOrder', 'Pages.SEP.Curiculum.ROSO', 'jour-menu-icon jour-administration', '',
                //     '', undefined,
                //     [
                //         new AppMenuItem('AnIntroductionByMichaelOBrooks', 'Pages.SEP.Curiculum.ROSO.IntroductionByBrooks', 'jour-menu-icon jour-settings', '/app/admin/introduction-by-michael'),
                //         new AppMenuItem('TheClearTheMechanismRitual', 'Pages.SEP.Curiculum.ROSO.ClearRitual', 'jour-menu-icon jour-settings', '/app/admin/clear-mechanism-ritual'),
                //         new AppMenuItem('ThePerfectWeekWithObservationZone', 'Pages.SEP.Curiculum.ROSO.PerfectWeek', 'jour-menu-icon jour-settings', '/app/admin/perfect-week-with-observation-zone'),
                //         new AppMenuItem('The90DayDecompressionRitual', 'Pages.SEP.Curiculum.ROSO.NinetyDays', 'jour-menu-icon jour-settings', '/app/admin/ninety-day-decompression-ritual'),
                //         new AppMenuItem('TheInterdependenceOfTheseRituals', 'Pages.SEP.Curiculum.ROSO.Interdependence', 'jour-menu-icon jour-settings', '/app/admin/interdependence-of-these-rituals')
                //     ]),

                new AppMenuItem('Resources', 'Pages.SEP.Curiculum.Resources', 'jour-menu-icon jour-FF__10d_Resources', '', '', undefined, [
                    new AppMenuItem('HowToUse', 'Pages.SEP.Curiculum.Resources.HowToUse', 'jour-menu-icon jour-FF__10d1_How-To-Use', '/app/admin/sep-how-to-use'),
                    new AppMenuItem('RitualsOfSelfOrder', 'Pages.SEP.Curiculum.Resources.SelfOrder', 'jour-menu-icon jour-FF__10d1_Rituals-of-Self-Order', '/app/admin/sep-curiculum-rituals-of-self-order'),
                    new AppMenuItem('2020System', 'Pages.SEP.Curiculum.Resources.2020', 'jour-menu-icon jour-FF__10d1_20-20-System', '/app/admin/sep-20-20-system'),
                    new AppMenuItem('1-3-10System', 'Pages.SEP.Curiculum.Resources.1310', 'jour-menu-icon jour-FF__10d1_1-3-10-System', '/app/admin/sep-1-3-10-system'),
                    new AppMenuItem('TVM', 'Pages.SEP.Curiculum.Resources.TVM', 'jour-menu-icon jour-FF__10d1_TVM', '/app/admin/sep-tvm'),
                    // new AppMenuItem('PWAI', 'Pages.SEP.Curiculum.Resources.PWAI', 'jour-menu-icon jour-settings', '/app/admin/sep-pwai'),
                    new AppMenuItem('Articles', 'Pages.SEP.Curiculum.Resources.Articles', 'jour-menu-icon jour-FF__10e1_Articles', '/app/admin/sep-articles'),
                ]),

            ]),
            new AppMenuItem('ThoughtLeadership', 'Pages.SEP.Curiculum.Articles', 'jour-menu-icon jour-FF__10e_Thought-Leadership', '', '', undefined, [
                new AppMenuItem('ServantLeader', 'Pages.SEP.Curiculum.Articles.ServantLeader', 'jour-menu-icon jour-FF__10e5_Servant-Leadership', '/app/admin/servant-leader'),
                new AppMenuItem('HumanQuotient', 'Pages.SEP.Curiculum.Articles.HumanQuotient', 'jour-menu-icon jour-FF__1_Human-Quotient-Hub', '/app/admin/human-quoitient'),
                new AppMenuItem('GiftedBook', 'Pages.SEP.Curiculum.Articles.GiftedBook', 'jour-menu-icon jour-FF__10e3_Gifted-Book-Series', '/app/admin/gifted-book'),
                new AppMenuItem('Videos', 'Pages.SEP.Curiculum.Articles.Videos', 'jour-menu-icon jour-FF__10e2_Videos', '/app/admin/videos'),
                new AppMenuItem('Articles', 'Pages.SEP.Curiculum.Articles.Articles', 'jour-menu-icon jour-FF__10e1_Articles', '/app/admin/sep-articles'),
            ]),

            new AppMenuItem('CompanyAccessMenuItem', 'Pages.Companies.CompanyAdmin', 'jour-menu-icon jour-FF__9_MP-Access', '/app/admin/company-settings/tab/sal-jgl'),

        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    getUserTenantInfo() {
        if (this._appSessionService.companyId != null && this._appSessionService.companyId != "null") {
            localStorage.setItem('forms_companyId', this._appSessionService.companyId);
        }
        else {
            localStorage.setItem('forms_companyId', "00000000-0000-0000-0000-000000000000");
        }
        localStorage.setItem('forms_userrole', this._appSessionService.user.userRoleName);
        if (this._appSessionService.tenantId != undefined && this._appSessionService.tenantId != null) {
            localStorage.setItem('forms_tenantId', this._appSessionService.tenantId.toString());
        }
        else {
            localStorage.setItem('forms_tenantId', "");
        }
        if (this._appSessionService.userId != undefined && this._appSessionService.userId != null) {
            localStorage.setItem('forms_userId', this._appSessionService.userId.toString());
        }
        else {
            localStorage.setItem('forms_userId', "");
        }
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        if (this._appSessionService.tenant != undefined && this._appSessionService.tenancyName != "" && this._appSessionService.user.userRoleName == "Admin" && menuItem.name == "Forms") {
            
            return false;
        }
        else {
            if (this._appSessionService.tenant != undefined && this._appSessionService.tenancyName != "" && this._appSessionService.user.userRoleName != "Admin" && menuItem.name == "FormsBuilder") {                

                return false;
            }
        }

        //if (this._appSessionService.tenant != undefined && this._appSessionService.tenancyName != "" && this._appSessionService.user.userRoleName == "Admin" && menuItem.name == "BusinessIdentityAssessment") {            
        //    return true;
        //}  

        if (menuItem.permissionName === 'Pages.DNA.CreateMyProfile') {
            menuItem.route = '/dna-profile/view/' + this._appSessionService.user.publicId;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.exceptPermissionName && this._permissionCheckerService.isGranted(menuItem.exceptPermissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.exceptTenantId && this._appSessionService.tenantId != menuItem.exceptTenantId) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}

import { Injectable } from "@angular/core";
import { SalesSummaryDatePeriod } from "@shared/service-proxies/service-proxies";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DateRangeWidgetService {
    private _dateRange$ = new Subject<SalesSummaryDatePeriod>();
    private _tenantId$ = new Subject<number[]>();
    private _companyId$ = new Subject<string[]>();
    private _editionId$ = new Subject<number[]>();

    public setDateRangeChangeTrigger(state: SalesSummaryDatePeriod): void {
        this._dateRange$.next(state);
    }
    public setCompanyIdChangeTrigger(state: string[]): void {
        this._companyId$.next(state);
    }
    public setTenantIdChangeTrigger(state: number[]): void {
        this._tenantId$.next(state);
    }

    public setEditionIdChangeTrigger(state: number[]): void {
        this._editionId$.next(state);
    }

    public dateRangeChangeTrigger$(): Observable<SalesSummaryDatePeriod> {
        return this._dateRange$.asObservable();
    }
    public tenantIdChangeTrigger$(): Observable<number[]> {
        return this._tenantId$.asObservable();
    }

    public editionIdChangeTrigger$(): Observable<number[]> {
        return this._editionId$.asObservable();
    }

    public companyIdChangeTrigger$(): Observable<string[]> {
        return this._companyId$.asObservable();
    }
}
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { Data, Route } from '@node_modules/@angular/router/src/config';
import { Observable } from '@node_modules/rxjs/internal/Observable';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService
    ) { }

    canActivateInternal(data: Data, state: RouterStateSnapshot): boolean {
        if (UrlHelper.isInstallUrl(location.href)) {
            return true;
        }
        
        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }

        if (!data || !data['permission']) {
            return true;
        }
        
        if (this._permissionChecker.isGranted(data['permission'])) {
            return true;
        }
        else if (data['permission'] === 'Pages.Administration.Users.FormsBuilder') {
            return true;
        }
        else if (data['permission'] === 'Pages.Administration.Users.BusinessIdentityAssessment') {
            return true;
        }
        else if (data['permission'] === 'Pages.Administration.Users.CapacityAssessment') {
            return true;
        }

        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, null);
    }

    selectBestRoute(): string {

        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('Pages.MessageBoard.View')) {
            return '/app/admin/message-boards';
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/institutions';
        }

        if (this._permissionChecker.isGranted('Pages.Companies')) {
            return '/app/admin/companies';
        }

        if (this._permissionChecker.isGranted('Pages.SEP.ViewMyData')) {
            return '/app/admin/sep-observation-zones';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.CoachingSessions.ViewMyData')) {
            return '/app/admin/coaching-sessions';
        }

        if (this._permissionChecker.isGranted('Pages.SEP.List')) {
            return '/app/admin/sep';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.DueDiligence.ViewMyData')) {
            return '/app/admin/due-diligence';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.SAL.ViewMyData')) {
            return '/app/admin/jgl';
        }

        if (this._permissionChecker.isGranted('Pages.Wins.ViewMyData')) {
            return '/app/admin/wins';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.Documents.ViewMyData')) {
            return '/app/admin/documents';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.WheelOfLife.ViewMyData')) {
            return '/app/admin/wheel-of-life';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.FiveWhys.ViewMyData')) {
            return '/app/admin/five-whys';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.GainAndLosses.ViewMyData')) {
            return '/app/admin/gain-and-losses';
        }

        if (this._permissionChecker.isGranted('Pages.DNA.CreateMyProfile')) {
            return '/dna-profile/view/' + this._sessionService.user.publicId;
        }

        // if (this._permissionChecker.isGranted('Pages.Administration.Host.Dashboard')) {
        //     return '/app/admin/hostDashboard';
        // }

        // if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
        //     return '/app/main/dashboard';
        // }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/institutions';
        }



        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.RequestAccess.ViewMyData')) {
            return '/app/admin/request-accesses';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users.FormsBuilder')) {
            return '/app/admin/forms-builder';
        }
        if (this._permissionChecker.isGranted('Pages.Administration.Users.BusinessIdentityAssessment')) {
            return '/app/admin/identity-assessment';
        }
        if (this._permissionChecker.isGranted('Pages.Administration.Users.CapacityAssessment')) {
            return '/app/admin/capacity-assessment';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.Forms')) {
            return '/app/admin/forms-builder';
        }

        if (this._permissionChecker.isGranted('Pages.CompanyUsers.BusinessIdentityAssessment')) {
            return '/app/admin/identity-assessment';
        }
        if (this._permissionChecker.isGranted('Pages.CompanyUsers.CapacityAssessment')) {
            return '/app/admin/capacity-assessment';
        }

        return '/app/notifications';
    }
}

import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ExternalModuleServiceProxy } from './ExternalModuleServiceProxy';

@NgModule({
        providers: [
                ApiServiceProxies.AuditLogServiceProxy,
                ApiServiceProxies.CachingServiceProxy,
                ApiServiceProxies.ChatServiceProxy,
                ApiServiceProxies.CommonLookupServiceProxy,
                ApiServiceProxies.EditionServiceProxy,
                ApiServiceProxies.FriendshipServiceProxy,
                ApiServiceProxies.HostSettingsServiceProxy,
                ApiServiceProxies.InstallServiceProxy,
                ApiServiceProxies.LanguageServiceProxy,
                ApiServiceProxies.NotificationServiceProxy,
                ApiServiceProxies.OrganizationUnitServiceProxy,
                ApiServiceProxies.PermissionServiceProxy,
                ApiServiceProxies.ProfileServiceProxy,
                ApiServiceProxies.RoleServiceProxy,
                ApiServiceProxies.SessionServiceProxy,
                ApiServiceProxies.TenantServiceProxy,
                ApiServiceProxies.TenantDashboardServiceProxy,
                ApiServiceProxies.TenantSettingsServiceProxy,
                ApiServiceProxies.TimingServiceProxy,
                ApiServiceProxies.UserServiceProxy,
                ApiServiceProxies.DashboardCustomizationServiceProxy,
                ApiServiceProxies.UserLinkServiceProxy,
                ApiServiceProxies.UserLoginServiceProxy,
                ApiServiceProxies.WebLogServiceProxy,
                ApiServiceProxies.AccountServiceProxy,
                ApiServiceProxies.TokenAuthServiceProxy,
                ApiServiceProxies.TenantRegistrationServiceProxy,
                ApiServiceProxies.HostDashboardServiceProxy,
                ApiServiceProxies.PaymentServiceProxy,
                ApiServiceProxies.DemoUiComponentsServiceProxy,
                ApiServiceProxies.InvoiceServiceProxy,
                ApiServiceProxies.SubscriptionServiceProxy,
                ApiServiceProxies.InstallServiceProxy,
                ApiServiceProxies.UiCustomizationSettingsServiceProxy,
                ApiServiceProxies.PayPalPaymentServiceProxy,
                ApiServiceProxies.StripePaymentServiceProxy,
                ApiServiceProxies.CompanyServiceProxy,
                ApiServiceProxies.CompanyUserServiceProxy,
                ApiServiceProxies.CompanyUserRequestAccessServiceProxy,
                ApiServiceProxies.WheelOfLifeServiceProxy,
                ApiServiceProxies.CoachingSessionsServiceProxy,
                ApiServiceProxies.DNAAssessmentServiceProxy,
                ApiServiceProxies.DNAProfileServiceProxy,
                ApiServiceProxies.CompanyUserDocumentsServiceProxy,
                ApiServiceProxies.CompanyDocumentsServiceProxy,
                ApiServiceProxies.FiveWhysServiceProxy,
                ApiServiceProxies.GainAndLossServiceProxy,
                ApiServiceProxies.SalServiceProxy,
                ApiServiceProxies.SalTaskServiceProxy,
                ApiServiceProxies.TeamChartServiceProxy,
                ApiServiceProxies.TeamChartStrategyServiceProxy,
                ApiServiceProxies.StatementTaskServiceProxy,
                ApiServiceProxies.CoachingSessions_V2ServiceProxy,
                ApiServiceProxies.DueDiligenceServiceProxy,

                ApiServiceProxies.AssessmentCategoryServiceProxy,
                ApiServiceProxies.AssessmentServiceProxy,
                ApiServiceProxies.ReviewStageServiceProxy,
                ApiServiceProxies.ScoreDescriptionServiceProxy,
                ApiServiceProxies.MessageBoardServiceProxy,
                ApiServiceProxies.PermanentRecommendationServiceProxy,
                ApiServiceProxies.AssessmentResultServiceProxy,
                ApiServiceProxies.AssessmentViewServiceProxy,
                ApiServiceProxies.AssessmentViewRuleServiceProxy,
                ApiServiceProxies.SepObservationZoneServiceProxy,
                ApiServiceProxies.SepAnnualPlannerServiceProxy,
                ApiServiceProxies.DashboardServiceProxy,
                ApiServiceProxies.SepIntakeFormServiceProxy,
                ApiServiceProxies.SepNinetyDayDecoServiceProxy,
                ApiServiceProxies.AnalyticsServiceProxy,
                ApiServiceProxies.MeSessionsServiceProxy,
                ApiServiceProxies.MattersOfImportanceServiceProxy,
                ApiServiceProxies.OII_SiteServiceProxy,
                ApiServiceProxies.OII_SiteAdditionalQuestionServiceProxy,
                ApiServiceProxies.OII_PublicSiteServiceProxy,
                ApiServiceProxies.Capacity_SiteServiceProxy,
                ApiServiceProxies.Capacity_SiteAdditionalQuestionServiceProxy,
                ApiServiceProxies.Capacity_PublicSiteServiceProxy,
                ExternalModuleServiceProxy,



                ApiServiceProxies.WinsServiceProxy,
                { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
        ]
})
export class ServiceProxyModule { }

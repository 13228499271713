import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const subjectSymbol = Symbol.for('unsubscribeSubject');

/**
 * Emits the values emitted by the source Observable until `component`
 * is destroyed
 *
 * **Lets values pass until the `component` is destroyed. Then, it completes.**
 *
 * ## Example
 * ```javascript
 * observable.pipe(takeUntilDestroyed(this)).subscribe(result => doSomething());
 * ```
 *
 * @see {@link takeUntil}
 *
 * @param {OnDestroy} component An component, which implements OnDestroy lifecycle hook. OnDestroy can be empty.
 * @return {Observable<T>} An Observable that emits the values from the source
 * Observable until `component` is destroyed.
 * @function takeUntilDestroyed
 */
export function takeUntilDestroyed<T>(component: OnDestroy) {
    if (!isInit(component)) {
        init(component);
    }

    return takeUntil<T>(getSubject(component));
}

function getSubject(component: OnDestroy) {
    return component[subjectSymbol];
}

function initSubject(component: OnDestroy) {
    if (!component[subjectSymbol]) {
        component[subjectSymbol] = new Subject();
    }

    return getSubject(component);
}

function isInit(component: OnDestroy): boolean {
    return !!getSubject(component);
}

/**
 * Forces the unsubscription call when component is destroyed
 */
function init(component: OnDestroy) {
    const subject = initSubject(component);

    // Wrap existing on destroy and
    // additionally call complete the unsubcription subject afterwards
    const initialOnDestroy = component.ngOnDestroy;
    component.ngOnDestroy = () => {
        initialOnDestroy.call(component);
        subject.next();
        subject.complete();
    };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'normalizeId'
})
export class NormalizeIdPipe implements PipeTransform {
    transform(key: string): string {
        return (key || '').split('-').join('');
    }
}

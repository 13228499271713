import { AfterViewInit, Directive, ElementRef, NgZone } from '@angular/core';

@Directive({
  selector: 'select'
})
export class SelectSafariFixDirective implements AfterViewInit {

  constructor(private _element: ElementRef, private _ngZone: NgZone) {
  }

  ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(
      () => {
        if (this._element.nativeElement) {
          this._element.nativeElement.addEventListener('click', (e: Event) => {
            e.stopPropagation();
          });
        }
      }
    );
  }

}

import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { API_BASE_URL, AssessmentDto } from "@shared/service-proxies/service-proxies";
import { Observable } from "rxjs";


@Injectable()
export class ExternalModuleServiceProxy {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : "";
    }
    
        /**
     * @param id (optional) 
     * @param companyId (optional) 
     * @return Success
     */
    gotoAssessmentBuilder(token: string | null | undefined, companyId: string | null | undefined, tenantId: string | null | undefined, userId: string | null | undefined, rootAddress: string | null | undefined): Observable<AssessmentDto> {
            let url_ = rootAddress + "/Auth/UserAuthentication?";
            if (token !== undefined)
                url_ += "token=" + encodeURIComponent("" + token) + "&";
            if (companyId !== undefined)
                url_ += "companyId=" + encodeURIComponent("" + companyId) + "&";
            if (tenantId !== undefined)
                url_ += "tenantId=" + encodeURIComponent("" + tenantId) + "&";
            if (userId !== undefined)
                url_ += "userId=" + encodeURIComponent("" + userId) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: any = {
                observe: "response",
                responseType: "blob",
                headers: new HttpHeaders({
                    "Accept": "application/json"
                })
            };
            //window.location.href = url_;
            window.open(url_, '_blank');
            return null;
            //return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_: any) => {
            //    return this.processGotoAssessmentBuilder(response_);
            //})).pipe(_observableCatch((response_: any) => {
            //    if (response_ instanceof HttpResponseBase) {
            //        try {
            //            return this.processGotoAssessmentBuilder(<any>response_);
            //        } catch (e) {
            //            return <Observable<AssessmentDto>><any>_observableThrow(e);
            //        }
            //    } else
            //        return <Observable<AssessmentDto>><any>_observableThrow(response_);
            //}));
        }
    
        // protected processGotoAssessmentBuilder(response: HttpResponseBase): Observable<AssessmentDto> {
        //     const status = response.status;
        //     const responseBlob =
        //         response instanceof HttpResponse ? response.body :
        //             (<any>response).error instanceof Blob ? (<any>response).error : undefined;
    
        //     let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); } };
        //     if (status === 200) {
        //         return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        //             let result200: any = null;
        //             let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        //             result200 = resultData200 ? AssessmentDto.fromJS(resultData200) : new AssessmentDto();
        //             return _observableOf(result200);
        //         }));
        //     } else if (status !== 200 && status !== 204) {
        //         return blobToText(responseBlob).pipe(_observableMergeMap(_responseText => {
        //             return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        //         }));
        //     }
        //     return _observableOf<AssessmentDto>(<any>null);
        // }
        
        /** End Assessment Builder *******/
    
        /** Start Business Identity Assessment *******/
    
        /**
         * @param id (optional) 
         * @param companyId (optional) 
         * @return Success
         */
        gotoBusinessIdentityAssessment(token: string | null | undefined, companyId: string | null | undefined, tenantId: string | null | undefined, userId: string | null | undefined, rootAddress: string | null | undefined, assessmentname: string | null | undefined): Observable<AssessmentDto> {
            let url_ = rootAddress + "/Auth/UserAuthentication?";
            if (token !== undefined)
                url_ += "token=" + encodeURIComponent("" + token) + "&";
            if (companyId !== undefined)
                url_ += "companyId=" + encodeURIComponent("" + companyId) + "&";
            if (tenantId !== undefined)
                url_ += "tenantId=" + encodeURIComponent("" + tenantId) + "&";
            if (userId !== undefined)
                url_ += "userId=" + encodeURIComponent("" + userId) + "&";
            if (assessmentname !== undefined)
                url_ += "assessmentname=" + encodeURIComponent("" + assessmentname) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: any = {
                observe: "response",
                responseType: "blob",
                headers: new HttpHeaders({
                    "Accept": "application/json"
                })
            };        
            window.open(url_, '_blank');
            return null;        
        }
    
        gotoPublicIdentityAssessment(rootAddress: string | null | undefined): Observable<AssessmentDto> {
            let url_ = rootAddress;        
            url_ = url_.replace(/[?&]$/, "");
            
            window.open(url_, '_blank');
            return null;
        }
    
        /** End Business Identity Assessment *******/
    
        /** Start Capacity Assessment *******/
    
        /**
         * @param id (optional) 
         * @param companyId (optional) 
         * @return Success
         */
        gotoCapacityAssessment(token: string | null | undefined, companyId: string | null | undefined, tenantId: string | null | undefined, userId: string | null | undefined, rootAddress: string | null | undefined, assessmentname: string | null | undefined): Observable<AssessmentDto> {
            let url_ = rootAddress + "/Auth/UserAuthentication?";
            if (token !== undefined)
                url_ += "token=" + encodeURIComponent("" + token) + "&";
            if (companyId !== undefined)
                url_ += "companyId=" + encodeURIComponent("" + companyId) + "&";
            if (tenantId !== undefined)
                url_ += "tenantId=" + encodeURIComponent("" + tenantId) + "&";
            if (userId !== undefined)
                url_ += "userId=" + encodeURIComponent("" + userId) + "&";
            if (assessmentname !== undefined)
                url_ += "assessmentname=" + encodeURIComponent("" + assessmentname) + "&";
            url_ = url_.replace(/[?&]$/, "");
    
            let options_: any = {
                observe: "response",
                responseType: "blob",
                headers: new HttpHeaders({
                    "Accept": "application/json"
                })
            };
            window.open(url_, '_blank');
            return null;
        }
    
        gotoPublicCapacityAssessment(rootAddress: string | null | undefined): Observable<AssessmentDto> {
            let url_ = rootAddress;
            url_ = url_.replace(/[?&]$/, "");
    
            window.open(url_, '_blank');
            return null;
        }
    
    /** End Capacity Assessment *******/
    
}